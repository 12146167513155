<template lang="pug">
  v-main
    router-view
</template>

<script>

export default {
  name: 'defaultlayout',

  data: () => ({
    //
  })
}
</script>

<style lang="scss" scoped>

</style>
